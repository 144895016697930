<template>
  <div class="mx-auto container container--fluid">
    <v-snackbar v-model="snackbar" :top="true" :color="color" :timeout="6000">
      <span v-if="snackbar_msg != null">{{ snackbar_msg }}</span>
      <v-btn dark text @click="snackbar = false">Close</v-btn>
    </v-snackbar>
    <v-toolbar flat dense color="white">
      <v-toolbar-title>Role</v-toolbar-title>
      <v-divider class="mx-4" inset vertical></v-divider>
    </v-toolbar>
    <v-divider class="mx-4"></v-divider>
    <v-app id="inspire" class="">
      <template v-if="load == true">
        <v-data-table :headers="headers" :items="role_list" class="elevation-1" :search="search">
          <template v-slot:top>
            <v-toolbar flat color="white">
              <v-text-field v-model="search" append-icon="search" label="Search" single-line hide-details>
              </v-text-field>
              <v-spacer></v-spacer>
              <span style="width: 20px"></span>
              <v-dialog v-model="dialog" max-width="500px">
                <template v-slot:activator="{ on }">
                  <v-btn color="indigo darken-4" dark class="mb-2" v-on="on" @click="iseditable = !iseditable">New
                    Role</v-btn>
                </template>
                <v-card>
                  <v-form v-model="valid">
                    <v-card-title>
                      <v-row justify="space-between">
                        <span class="headline">{{ formTitle }}</span>
                        <v-btn icon dark @click="dialog = false">
                          <v-icon color="black">mdi-close</v-icon>
                        </v-btn>
                      </v-row>
                      <!-- <span class="headline">{{ formTitle }}</span> -->
                    </v-card-title>

                    <v-card-text>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12" v-if="editedIndex == -1">
                            <v-autocomplete :items="user_list" v-model="editedItem.usertype" label="Select User Type"
                              :rules="[(v) => !!v || 'required']" persistent-hint :hint="editedIndex == -1
                                ? 'For example :: TPO,Faculty,Student'
                                : 'For example :: TPO,Faculty,Student'
                                "></v-autocomplete>
                          </v-col>
                          <v-col cols="12" v-if="editedIndex != -1">User Type
                            <v-select dense v-model="editedItem.usertype" :items="user_list" item-value="id"
                              persistent-hint :hint="editedIndex != -1
                                ? 'For example :: TPO,Faculty,Student'
                                : 'For example :: TPO,Faculty,Student'
                                " :rules="[(v) => !!v || 'required']"></v-select>
                          </v-col>
                        </v-row>
                        <v-row dense>
                          <v-col cols="12">
                            <v-text-field v-model="editedItem.name" label="Role" dense persistent-hint
                              :rules="[(v) => !!v || 'required']" :hint="editedIndex == -1
                                ? 'For example :: TPO,Faculty,Student'
                                : 'For example :: TPO,Faculty,Student'
                                "></v-text-field>
                          </v-col>
                          <v-col>
                            <input type="hidden" v-model="editedItem.id" name="id" />
                            <!-- <v-hidden v-model="editedItem.id" label="id"></v-hidden> -->
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col cols="6" sm="3" md="2">
                            <v-checkbox v-model="editedItem.isactive" color="success" label="isActive?"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn color="blue darken-1" outlined text @click="close">Cancel</v-btn>
                      <v-btn color="primary darken-1" dark @click="save">Save</v-btn>
                      <!-- :disabled="!valid" -->
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.isactive="{ item }">
            <v-switch v-model="item.isactive" @change="activeswitch(item)"
              :labels="{ checked: 'On', unchecked: 'Off' }"></v-switch>
          </template>
          <template v-slot:item.action="{ item }">
            <div>
              <v-avatar style="" size="25" class="edit-avatar">
                <v-icon class="edit-v-icon mr-2" @click="editItem(item)">edit</v-icon>
              </v-avatar>
              <v-btn text>
                <v-icon color="red" @click="deletefunc(item.id)">
                  mdi-delete</v-icon></v-btn>
            </div>
          </template>
        </v-data-table>
      </template>
    </v-app>
  </div>
</template>

<script>
import axios from "axios";
export default {
  data: () => ({
    overlay: false,
    snackbar_msg: "",
    valid: false,
    color: "",
    snackbar: false,
    iseditable: false,
    load: false,
    dialog: false,
    errorMsg: {
      name: false,
      usertype: false,
    },
    role_list: null,
    user_list: null,
    search: "",
    headers: [
      {
        text: "Role",
        value: "name",
      },
      {
        text: "User Type",
        value: "usertype",
      },
      {
        text: "Status",
        value: "isactive",
      },
      {
        text: "Actions",
        value: "action",
        sortable: false,
      },
    ],

    editedIndex: -1,
    editedItem: {
      id: 0,
      name: "",
      usertype: "",
      isactive: true,
    },
    defaultItem: {
      id: 0,
      name: "",
      usertype: "",
      isactive: true,
    },
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Role" : "Edit Role";
    },
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    overlay(val) {
      val = () => {
        this.overlay = false;
      };
    },
  },
  mounted() {
    this.onLoad();
  },

  methods: {
    hasWhiteSpace(s) {
      var status = false;
      var reWhiteSpace = new RegExp(/^\s+$/);

      // Check for white space
      if (reWhiteSpace.test(s)) {
        alert("Please Check Your Fields For Spaces");
        //   return true;
        return /^\s/.test(s);
      }
      //   return false;
      return /^\s/.test(s);
    },
    initializeErrorMsgFalse() {
      this.errorMsg.name = false;
      this.errorMsg.usertype = false;
    },
    onLoad() {
      this.overlay = true;
      axios

        .post("/AdminMaster/addRole")

        .then((res) => {
          if (res.data.msg == "200") {
            //window.alert(res.data.msg)

            if ((this.load = true)) {
              this.overlay = false;
              this.role_list = res.data.role_list;
              this.user_list = res.data.user_list;
              this.load = true;
            }
          } else {
            window.console.log(res.data.msg);
          }
        })
        .catch((error) => {
          this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
          //window.console.log(error);
        })
        .finally(() => {
          "use strict";
          var overlay = false;
        });
    },
    editItem(item) {
      this.iseditable = false;
      this.editedIndex = this.role_list.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },
    showSnackbar(clr, msg) {
      this.snackbar = true;
      this.color = clr;
      this.snackbar_msg = msg;
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },

    save() {
      if (this.editedItem.usertype == "") {
        this.showSnackbar(
          "red",
          " Please Select User Type..."
        ); // show snackbar on success
        return;
      }
      else if (this.editedItem.name == "" || /^[ \d]+$/.test(this.editedItem.name) || this.hasWhiteSpace(this.editedItem.name)) {
        this.showSnackbar(
          "red",
          " Please Enter Role..."
        ); // show snackbar on success
        return;
      }

      else {
        if (this.editedIndex > -1) {
          //  console.log("edit.." + this.editedItem);
          this.iseditable = false;
          axios
            .post("/AdminMaster/editRole", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                Object.assign(this.role_list[this.editedIndex], this.editedItem);

                this.showSnackbar("#4caf50", "Role Updated Successfully..."); // show snackbar on success
              } else if (res.data.status.code == "NA") {
                //alert(res.data.status.message)
                this.showSnackbar("#b71c1c", "Role Already Present!!!"); // show snackbar on error
              }
            })
            .catch((error) => {
              this.showSnackbar("#b71c1c", "Something went wrong..."); // show snackbar
              //window.console.log(error);
            });
        } else {
          axios
            .post("/AdminMaster/saveRole", this.editedItem)
            .then((res) => {
              if (res.data.status.code == "SUCCESS") {
                this.role_list.push(this.editedItem);
                this.showSnackbar("#4caf50", "New Role Added Successfully!!!"); // show snackbar on success
              } else if (res.data.status.code == "NA") {
                //alert(res.data.status.message)
                this.showSnackbar("#b71c1c", "Role Already Present!!!"); // show snackbar on error
              }
            })
            .catch((error) => {
              window.console.log(error);
            });
        }
        this.close();
      }

    },
    deletefunc(item) {
      const data = {
        deleteitem: item,
      };
      axios
        .post("/AdminMaster/deleteRole", data)
        .then((res) => {
          //console.log("a")
          if (res.data.msg == "200") {
            this.showSnackbar("#4caf50", "Deleted Successfully...");
            this.onLoad();
          } else {
            //error
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
    activeswitch(item) {
      //console.log("abc");

      const data = {
        deleteitem: item.id,
      };
      //console.log(item.id);

      axios
        .post("/AdminMaster/activerole", data)
        .then((res) => {
          //   console.log("res.data");
          //   console.log(res.data);
          if (res.data.msg == "200") {
            // console.log("res.data");
            // console.log(res.data);
            // console.log("success");
            // this.showSnackbar("#4caf50", res.data.message);
            this.onLoad();
          } else {
            //console.log("fail");
            this.showSnackbar("#b71c1c", res.data.msg);
          }
        })
        .catch((error) => {
          window.console.log(error);
        });
      this.close();
    },
  },
};
</script>
<style scoped>
.v-text-field {
  padding-top: 0px;
  margin-top: 0px;
}

.v-icon-size {
  font-size: 20px;
}

.edit-avatar {
  transition: 0.3s;
  border: 1px solid #b0bec5;
}

.edit-avatar:hover {
  background-color: #b0bec5;
}

.edit-v-icon {
  padding-left: 6px;
  font-size: 15px;
}

.edit-v-icon:hover {
  color: white;
}
</style>
